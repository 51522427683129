import React, { useState, useEffect, useContext } from 'react'
import NewReservationContainer from '../../../../../containers/backoffice/NewReservationContainer'
import LocationsTabContainer from '../../../../../containers/backoffice/Common/Locations'
import UsersTabContainer from '../../../../../containers/backoffice/Common/Users'
import BiohazardTabContainer from '../../../../../containers/backoffice/Covid/Biohazard'
import ReservationsTabContainer from '../../../../../containers/backoffice/WsManager/Reservations'
import LogTabContainer from '../../../../../containers/backoffice/Common/Log'
import ReportsTabContainer from '../../../../../containers/backoffice/Common/Reports'
import SettingsTabContainer from '../../../../../containers/backoffice/Common/Settings'
import ContactReportsTabContainer from '../../../../../containers/backoffice/Covid/ContactReports'
import CheckInReportsTabContainer from '../../../../../containers/backoffice/Covid/CheckInReports'
import { Tab } from 'semantic-ui-react';
import { injectIntl } from 'react-intl';
import { APP } from '../../../../../interfaces/SwitchState';
import { AbilityContext } from '../../../../../utils/permissions/Can'
import {Common} from '../../../../../utils/Common';
import NewReservationV1 from '../../../../tracker/Card/NewReservation_v1/NewReservation_v1';
import BookingTabContainer from '../../../../../containers/backoffice/Common/Booking'

import './Home.scss'

const Home = (props: any) => {
    const { formatMessage } = props.intl;
    const [activeIndex, setActiveIndex] = useState(0)
    const ability = useContext(AbilityContext);
    let allTabs: any[] = [];
    
    // Controllo su mobile_user non corretto. Sarebbe da debuggare, ma non è strettamente necessario al momento.
    // let isMobileUser= (props.auth !== undefined) ? props.auth.roles[0].roles.find((o: any,i: any) => (o === 'mobile_user') ? true: false ) : ''

    //console.log("process.env.REACT_APP_MOBILE_BOOKING ", process.env.REACT_APP_MOBILE_BOOKING)

    // TODO: anche il controllo su questa variabile potrebbe essere rimosso, in quanto non è più neccessario.
    if(process.env.REACT_APP_MOBILE_BOOKING === "true" ){
        
        // Per la corretta visualizzazione dell'app V2 in produzione non serve più.
        // if(isMobileUser === 'mobile_user'){
            allTabs = [
                {
                    menuItem: formatMessage({ id: 'app.tab.locations' }),
                    render: () => <LocationsTabContainer adminView={true} name="Manage" />,
                    app: '',
                    permissions: [
                        { I: 'manage', a: 'Location' },
                    ]
                },
                {
                    menuItem: formatMessage({ id: 'app.tab.booking_v2' }),
                    render: () => <NewReservationContainer adminView={false} name="Booking" />,
                    app: APP.WSMANAGER,
                    permissions: [
                        { I: 'create', a: 'ReservationV1' },
                    ]
                },
                {
                    menuItem: formatMessage({ id: 'app.tab.reservations' }),
                    render: () => <ReservationsTabContainer isModerator={false} onClick={onTabChange()} />,
                    app: APP.WSMANAGER,
                    permissions: [
                        { I: 'update', a: 'Reservation' },
                    ]
                },
                {
                    menuItem: formatMessage({ id: 'app.tab.requests' }),
                    render: () => <ReservationsTabContainer isModerator={true} onClick={onTabChange()} />,
                    app: APP.WSMANAGER,
                    permissions: [
                        { I: 'manage', a: 'Reservation' },
                    ]
                },
                {
                    menuItem: formatMessage({ id: 'app.tab.users' }),
                    render: () => <UsersTabContainer />,
                    app: '',
                    permissions: [
                        { I: 'manage', a: 'User' },
                    ]
                },
                {
                    menuItem: formatMessage({ id: 'app.tab.biohazard' }),
                    render: () => <BiohazardTabContainer />,
                    app: APP.COVID19,
                    permissions: [
                        { I: 'read', a: 'Biohazard' },
                    ]
                },
                {
                    menuItem: formatMessage({ id: 'app.tab.contact_reports' }),
                    render: () => <ContactReportsTabContainer />,
                    app: APP.COVID19,
                    permissions: [
                        { I: 'read', a: 'ContactReports' },
                    ]
                },
                {
                    menuItem: formatMessage({ id: 'app.tab.checkin_reports' }),
                    render: () => <CheckInReportsTabContainer />,
                    app: '',
                    permissions: [
                        { I: 'read', a: 'CheckInReport' },
                    ]
                },
                {
                    menuItem: formatMessage({ id: 'app.tab.reports' }),
                    render: () => <ReportsTabContainer />,
                    app: '',
                    permissions: [
                        { I: 'read', a: 'Reports' },
                    ]
                },
                {
                    menuItem: formatMessage({ id: 'app.tab.log' }),
                    render: () => <LogTabContainer />,
                    app: '',
                    permissions: [
                        { I: 'read', a: 'Log' },
                    ]
                },
                {
                    menuItem: { id: 'app.tab.settings', icon: 'settings', position:'right'},
                    render: () => <SettingsTabContainer />,
                    app: '',
                    permissions: [
                        { I: 'manage', a: 'Settings' }, 
                    ]
                },
        
            ]
            // Non serve visualizzare la vecchia app per le prenotazioni. 
            // Non viene più fatto il controllo sul permesso mobile_user, poiché  il permesso booker non è più presente.
        // } else{
        //     allTabs = [
        //         {
        //             menuItem: formatMessage({ id: 'app.tab.locations' }),
        //             render: () => <LocationsTabContainer adminView={true} name="Manage" />,
        //             app: '',
        //             permissions: [
        //                 { I: 'manage', a: 'Location' },
        //             ]
        //         },
        //         {
        //             menuItem: formatMessage({ id: 'app.tab.booking' }),
        //             render: () => <LocationsTabContainer adminView={false} name="Booking" />,
        //             app: APP.WSMANAGER,
        //             permissions: [
        //                 { I: 'create', a: 'Reservation' },
        //             ]
        //         },
        //         {
        //             menuItem: formatMessage({ id: 'app.tab.reservations' }),
        //             render: () => <ReservationsTabContainer isModerator={false} onClick={onTabChange()} />,
        //             app: APP.WSMANAGER,
        //             permissions: [
        //                 { I: 'update', a: 'Reservation' },
        //             ]
        //         },
        //         {
        //             menuItem: formatMessage({ id: 'app.tab.requests' }),
        //             render: () => <ReservationsTabContainer isModerator={true} onClick={onTabChange()} />,
        //             app: APP.WSMANAGER,
        //             permissions: [
        //                 { I: 'manage', a: 'Reservation' },
        //             ]
        //         },
        //         {
        //             menuItem: formatMessage({ id: 'app.tab.users' }),
        //             render: () => <UsersTabContainer />,
        //             app: '',
        //             permissions: [
        //                 { I: 'manage', a: 'User' },
        //             ]
        //         },
        //         {
        //             menuItem: formatMessage({ id: 'app.tab.biohazard' }),
        //             render: () => <BiohazardTabContainer />,
        //             app: APP.COVID19,
        //             permissions: [
        //                 { I: 'read', a: 'Biohazard' },
        //             ]
        //         },
        //         {
        //             menuItem: formatMessage({ id: 'app.tab.contact_reports' }),
        //             render: () => <ContactReportsTabContainer />,
        //             app: APP.COVID19,
        //             permissions: [
        //                 { I: 'read', a: 'ContactReports' },
        //             ]
        //         },
        //         {
        //             menuItem: formatMessage({ id: 'app.tab.checkin_reports' }),
        //             render: () => <CheckInReportsTabContainer />,
        //             app: '',
        //             permissions: [
        //                 { I: 'read', a: 'CheckInReport' },
        //             ]
        //         },
        //         {
        //             menuItem: formatMessage({ id: 'app.tab.reports' }),
        //             render: () => <ReportsTabContainer />,
        //             app: '',
        //             permissions: [
        //                 { I: 'read', a: 'Reports' },
        //             ]
        //         },
        //         {
        //             menuItem: formatMessage({ id: 'app.tab.log' }),
        //             render: () => <LogTabContainer />,
        //             app: '',
        //             permissions: [
        //                 { I: 'read', a: 'Log' },
        //             ]
        //         },
        //         {
        //             menuItem: { id: 'app.tab.settings', icon: 'settings', position:'right'},
        //             render: () => <SettingsTabContainer />,
        //             app: '',
        //             permissions: [
        //                 { I: 'manage', a: 'Settings' }, 
        //             ]
        //         },
        
        //     ]
        // }
    }else{

        allTabs = [
            {
                menuItem: formatMessage({ id: 'app.tab.locations' }),
                render: () => <LocationsTabContainer adminView={true} name="Manage" />,
                app: '',
                permissions: [
                    { I: 'manage', a: 'Location' },
                ]
            },
            {
                menuItem: formatMessage({ id: 'app.tab.booking' }),
                render: () => <LocationsTabContainer adminView={false} name="Booking" />,
                app: APP.WSMANAGER,
                permissions: [
                    { I: 'create', a: 'Reservation' },
                ]
            },/*
            {
                menuItem: formatMessage({ id: 'app.tab.booking' }),
                render: () => <BookingTabContainer adminView={false} name="Booking" />,
                app: APP.WSMANAGER,
                permissions: [
                    { I: 'create', a: 'Reservation' },
                ]
            },*/
            {
                menuItem: formatMessage({ id: 'app.tab.reservations' }),
                render: () => <ReservationsTabContainer isModerator={false} onClick={onTabChange()} />,
                app: APP.WSMANAGER,
                permissions: [
                    { I: 'update', a: 'Reservation' },
                ]
            },
            {
                menuItem: formatMessage({ id: 'app.tab.requests' }),
                render: () => <ReservationsTabContainer isModerator={true} onClick={onTabChange()} />,
                app: APP.WSMANAGER,
                permissions: [
                    { I: 'manage', a: 'Reservation' },
                ]
            },
            {
                menuItem: formatMessage({ id: 'app.tab.users' }),
                render: () => <UsersTabContainer />,
                app: '',
                permissions: [
                    { I: 'manage', a: 'User' },
                ]
            },
            {
                menuItem: formatMessage({ id: 'app.tab.biohazard' }),
                render: () => <BiohazardTabContainer />,
                app: APP.COVID19,
                permissions: [
                    { I: 'read', a: 'Biohazard' },
                ]
            },
            {
                menuItem: formatMessage({ id: 'app.tab.contact_reports' }),
                render: () => <ContactReportsTabContainer />,
                app: APP.COVID19,
                permissions: [
                    { I: 'read', a: 'ContactReports' },
                ]
            },
            {
                menuItem: formatMessage({ id: 'app.tab.checkin_reports' }),
                render: () => <CheckInReportsTabContainer />,
                app: '',
                permissions: [
                    { I: 'read', a: 'CheckInReport' },
                ]
            },
            {
                menuItem: formatMessage({ id: 'app.tab.reports' }),
                render: () => <ReportsTabContainer />,
                app: '',
                permissions: [
                    { I: 'read', a: 'Reports' },
                ]
            },
            {
                menuItem: formatMessage({ id: 'app.tab.log' }),
                render: () => <LogTabContainer />,
                app: '',
                permissions: [
                    { I: 'read', a: 'Log' },
                ]
            },
            {
                menuItem: { id: 'app.tab.settings', icon: 'settings', position:'right'},
                render: () => <SettingsTabContainer />,
                app: '',
                permissions: [
                    { I: 'manage', a: 'Settings' }, 
                ]
            },
    
        ]

    }
    



    const [tabs, setTabs] = useState([])

    useEffect(() => {
        if (props.caslInitialized) {
            
            setCurrentTabs()
        }
    }, [props.caslInitialized])

    useEffect(() => {
        setCurrentTabs()
        setActivePane({ activeIndex: 0 })

    }, [props.activeApp])

    const setCurrentTabs = () => {
        let tabs: any = []
        setTabs(tabs)

        for (var i in allTabs) {
            let tab = allTabs[i]

            if (tab.app === '' || tab.app === props.activeApp) {
                for (var x in tab.permissions) {
                    let rule = tab.permissions[parseInt(x)]

                    if (ability.can(rule.I, rule.a)) {
                        tabs.push(tab)
                        break
                    }
                }
            }
        }
        setTabs(tabs)
    }

    const onTabChange = () => {
        props.onSwitchTab();
    }

    const setActivePane = (data: any) => {
        console.log("TAB DATA", data)
        if (data.activeIndex !== activeIndex) {
            setActiveIndex(data.activeIndex)
        }
    }

    return <Tab
        className="h-100 w-100 _flex flex-direction-column px-3"
        activeIndex={activeIndex}
        onTabChange={(e: any, data: any) => { setActivePane(data) }}
        panes={tabs}
    />
}

export default injectIntl(Home)