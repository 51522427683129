import React from 'react'
import { Tab, Form, Divider, Label } from 'semantic-ui-react'
import './ConfirmForm.scss';
import { BaseReservation, FormReservation } from '../../../../../models/Reservation';
import { injectIntl } from 'react-intl';
import { ProgressSpinner } from 'primereact/progressspinner';
import moment from 'moment';


const ConfirmFormTab = (props: any) => {

  const onBooking = () => {
    let auth: any = localStorage.getItem("auth")
    let userInfo = JSON.parse(auth)

    let formDetail: FormReservation = props.formDetail
    console.log("formDetail.selfReservation", formDetail.selfReservation)
    
    for (var d = new Date(formDetail.startDate); d <= new Date(formDetail.endDate); d.setDate(d.getDate() + 1)) {
      let booking_datetime_start = moment(d).format('YYYY-MM-DD').split("T")[0] + " 09:00:00"
      let reservation = {
        location_guid: formDetail.desk,
        booker_guid: userInfo.guid,
        include_myself: formDetail.selfReservation? 1 : 0,
        booking_datetime_start: booking_datetime_start,
        booking_datetime_end: moment(d).format('YYYY-MM-DD').split("T")[0] + " 19:00:00",
        booking_reason_guid: formDetail.reason,
        entrance_time: booking_datetime_start,
        tags: formDetail.listUser.filter(e => e !== userInfo.guid)
      }
      props.setLoading(true)
      let r = new BaseReservation().set(reservation)
      let array: any = []
      let email_array: any = []
      for (var i in r.tags) {
        array.push({ guid: r.tags[i] })
        email_array.push({ email: r.tags[i].email })
      }
      r.tags = array
      props.onBooking(r, email_array, formDetail.reason, formDetail.location)
    }
    props.setFormDetail(new FormReservation())
    props.setLoading(false)
    props.setSuccessBooking(true)

  }

  return (
    <Tab.Pane className="Contacts">
      <div className="container">
        {props.bookingState?.loading ? (<ProgressSpinner />)
          : (
            <Form className='formStyle'>
              <Form.Group className='formGroup' widths='equal' >
                <Form.Field
                  label='Quando'
                  control='Label'
                >

                  <Label className='labelDesc'>{props.formDetail.startDate} 09:00 - {props.formDetail.endDate} 19:00</Label>
                </Form.Field>

                <Form.Field
                  label='Sede'
                  control='Label'
                >
                  <Label className='labelDesc'>{props.selectedLocation ? props.selectedLocation.name : ""} - {props.selectedFloor ? props.selectedFloor.name : ""} - {props.selectedRoom ? props.selectedRoom.name : ""} - {props.selectedDesk ? props.selectedDesk.name : ""}</Label>
                </Form.Field>
                <Form.Field
                  label='Motivazione personale'
                  control='Label'
                >
                  <Label className='labelDesc'>{props.selectedReason ? props.selectedReason.text : ""}</Label>

                </Form.Field>
              </Form.Group>
              <Divider />
              <Form.Field
                label='Prenotazione per'
                control='Label'
              >
                {
                  props.selectedUsers && props.selectedUsers.map((user: any) => {
                    return (<Label className='labelPeople'>{user}</Label>)
                  })

                }

              </Form.Field>

              <Form.Group className='formGroup' widths='equal' >

                {/* <Form.Checkbox label='I agree to the Terms and Conditions' /> */}
                <Form.Button className="backButton" onClick={() => { props.setStepConfirmForm(0) }}>Indietro</Form.Button>

                <Form.Button className="confirmButton" onClick={() => {
                  onBooking()
                  props.setStepConfirmForm(0)
                }}>Conferma prenotazione</Form.Button>
              </Form.Group>

            </Form>
          )
        }
      </div>
    </Tab.Pane >
  )
}

export default ConfirmFormTab